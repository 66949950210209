import PropTypes from "prop-types"
import React, { useEffect } from "react"
import Logo from "../svgs/stepone_logo.inline.svg"
import '../styles/navmenu.scss'
import '../styles/global.css'
import anime from 'animejs/lib/anime.es.js';
//import MagnetMouse from 'magnet-mouse';

import AOS from 'aos';
import 'aos/dist/aos.css';

import TransitionLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink";



import Menu from './menu'
import { menuAnimationIn, menuAnimationOut } from './menu'
import MenuOverlay from "./menuOverlay";

let currentClass = "hamburger hamburger--collapse z-30 relative"


export function openMenu() {
  const button = document.querySelector(".hamburger");
  const sidebar = document.getElementById('main-menu');
  const body = document.body
  if (currentClass === "hamburger hamburger--collapse z-30 relative") {
    
    currentClass += " is-active"
    sidebar.style.transform = "translateX(0)"
    document.getElementById('main-menu').style.opacity = "100%"
    document.getElementById('menu-overlay').style.display = "block"
    setTimeout(() => {document.getElementById('menu-overlay').style.opacity = "10%"},100)

    menuAnimationIn()
    body.className += ' preventScroll'

  } else {
    currentClass = "hamburger hamburger--collapse z-30 relative"
    sidebar.style.transform = "translateX(100%)"
    document.getElementById('main-menu').style.opacity = "0"
    body.className -= ' preventScroll'
    menuAnimationOut()
    setTimeout(() => {document.getElementById('menu-overlay').style.display = "none"},200)
    document.getElementById('menu-overlay').style.opacity = "0%"

  }
  //currentClass = (currentClass === "hamburger hamburger--collapse z-30 relative") ? "hamburger hamburger--collapse z-30 relative" : "hamburger hamburger--collapse z-30 relative is-active"
  button.className = currentClass;
}


const Header = ({ siteTitle }) => {

  useEffect(() => {

    var menuElement = document.getElementsByClassName('menu-elm')
    var a = 0;
    for (a = 0; a < menuElement.length; a++) {
        menuElement[a].addEventListener("mouseover", function() {
            //this.classList.toggle('translate-anim')
            this.style.transform = 'translateX(1rem)'
        })
        menuElement[a].addEventListener("mouseout", function() {
            //this.classList.toggle('translate-anim')
            this.style.transform = 'translateX(0)'
        })
    }

    AOS.init(
      {
        offset: 100,
        easing: 'ease-in-out-cubic'
      }
    );
  })






  function logoAnimation() {
    anime({
      targets: '.logoAnimation',
      fill: ['#000', '#fff'],
      duration: 2500
    });
    anime({
      targets: '.logoAnimation path',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 800,
      delay: function(el, i) { return i * 50 },
      direction: 'alternate',
      loop: false
    });
    anime({
      targets: '.logoAnimation path',
      easing: 'easeInOutSine',
      duration: 800,
      delay: function(el, i) { return i * 50 },
      opacity: [0,1],
      loop: false
    });
    
  }

  function logoAnimationOut() {
    anime({
      targets: '.logoAnimation',
      fill: ['#fff','#000'],
      duration: 2500
    });
  }

  
  
  

  useEffect(() => {

    currentClass = "hamburger hamburger--collapse z-30 relative"
    document.body.className -= ' preventScroll'
    
  })

  return(
  <header>
    <Menu/>
    <MenuOverlay menuFunction={openMenu}/>
    <nav className="flex items-center justify-between flex-wrap bg-white px-6 py-6 lg:p-12 z-0">
    <AniLink to="/" paintDrip duration={1} hex="#000">
      <div className="flex items-center flex-shrink-0 text-white mr-6" onMouseEnter={logoAnimation} onMouseLeave={logoAnimationOut} role="menuitem" tabIndex={0}>
        <Logo className="h-4 logoAnimation"/>
      </div>
    </AniLink>
      <div className="block z-50">
      <button className="hamburger hamburger--collapse z-50 relative magnetelm" type="button" onClick={openMenu}>
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>  
      </div>
    </nav>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header


