import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from './cookieConsent'
import LoadingScreen from './loadingScreen'

import Header from "./header"
import Footer from "./footer"

import splitbee from '@splitbee/web';
//import Menu from "./menu"

function getCookie(cname) {
  if (typeof window !== `undefined`) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}

function checkLoadedCookie() {
  var loaded = getCookie("loaded");
  if (loaded == "") {
      return true
  } else {
      return false;
    }
  }



const Layout = ({ children}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    /*const body = document.body,
    scrollWrap = document.getElementsByClassName("smooth-scroll-wrapper")[0],
    //height = scrollWrap.getBoundingClientRect().height - 1500,
    height = scrollWrap.style.height - 1,
    speed = 0.08;
  
    var offset = 0;
  
    body.style.height = Math.floor(height) + "px";
    //body.style.height = "2000px";
  
    function smoothScroll() {
      offset = Math.max(0.1, offset + (window.pageYOffset - offset) * speed)
    
      var scroll = "translateY(-" + offset + "px) translateZ(0)";
      scrollWrap.style.transform = scroll;
    
      var callScroll = requestAnimationFrame(smoothScroll);
    }

    smoothScroll();*/

    // This initiliazes Splitbee.js
    splitbee.init() 
  })

  return (
    //<div className="smooth-scroll-wrapper overflow-hidden">
      <div className="overflow-hidden relative">
        { checkLoadedCookie() && 
          <LoadingScreen />
        }
        <Header siteTitle={data.site.siteMetadata.title} />
        <CookieConsent />
        

        <div className="px-6 md:px-20 lg:px-32 xl:px-56 mainContainer">
          <main>
          {children}
          
          </main>
        </div>

        <Footer />
      </div>
    //</div>
    
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
