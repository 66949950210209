import React from "react"
import { Link } from "gatsby"

import { data } from '../data/basics'

import '../styles/footer.scss'
import '../styles/animations.scss'

const mail = "mailto:" + data.contactEmail



function facebookColor() {
    const footer = document.querySelector('.footer-height')
    footer.style.backgroundColor = '#4267B2'
}

function instagramColor() {
    const footer = document.querySelector('.footer-height')
    footer.style.backgroundColor = '#E1306C'
}

function behanceColor() {
    const footer = document.querySelector('.footer-height')
    footer.style.backgroundColor = '#1769ff'
}

function dribbbleColor() {
    const footer = document.querySelector('.footer-height')
    footer.style.backgroundColor = '#ea4c89'
}

function blackColor() {
    const footer = document.querySelector('.footer-height')
    footer.style.backgroundColor = '#000'
}

const Footer = () => (
    <footer className="footer-height bg-black text-white flex flex-col justify-between">
        <div id="main-title-container" className="footer-container-height absolute align-middle">
            <Link to="/kontakt" className="text-3xl text-center my-auto relative footer-title lg:text-5xl anim-underline inline-block">Lass uns reden.</Link>
        </div>
        <div>
            <div className="px-6 py-6 lg:p-12  flex flex-col flex-1 mr-auto flex-wrap lg:flex-no-wrap items-start lg:flex-row lg:justify-between lg:items-end lg:bottom-0">
                <div id="email" className="flex-1">
                    <a  className="underline" href={mail}>{data.contactEmail}</a>
                    <br className="hidden lg:inline"/>
                    <br className="hidden lg:inline"/>
                    <p className="hidden lg:inline">{data.adresse.street}</p>
                    <br className="hidden lg:inline"/>
                    <p className="hidden lg:inline">{data.adresse.town}</p>
                </div>
                <div id="social-media" className="flex flex-col flex-1 items-start pt-3 lg: pt-0 lg:flex-row lg:justify-center lg:items-center">
                    <a className="underline lg:px-4" href={data.socialMedia.facebook} onMouseOver={facebookColor} onFocus={facebookColor} onMouseOut={blackColor} onBlur={blackColor}>Facebook</a>
                    <a className="underline lg:px-4" href={data.socialMedia.instagram} onMouseOver={instagramColor} onFocus={instagramColor} onMouseOut={blackColor} onBlur={blackColor}>Instagram</a>
                    <a className="underline lg:px-4" href={data.socialMedia.behance} onMouseOver={behanceColor} onFocus={behanceColor} onMouseOut={blackColor} onBlur={blackColor}>Behance</a>
                    <a className="underline lg:px-4" href={data.socialMedia.dribbble} onMouseOver={dribbbleColor} onFocus={dribbbleColor} onMouseOut={blackColor} onBlur={blackColor}>Dribbble</a>
                </div>
                <div id="legal" className="flex flex-row items-start flex-1  lg:aml-auto justify-around pt-3 lg: pt-0 lg:flex-row lg:justify-end lg:items-center">
                    <Link to="/datenschutz" className="lg:pr-4">Datenschutzerklärung</Link>
                    <Link to="/impressum" className="pl-4 lg:pl-4">Impressum</Link>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer