import React, { useEffect } from "react"
import '../styles/loadingScreen.scss'
import anime from 'animejs/lib/anime.es.js';
import Logo from "../svgs/stepone_logo_loading.inline.svg"

const LoadingScreen = () => {

    async function doLoadingAnimation() {
        document.body.className += ' preventScroll'
        let animationDone = new Promise((resolve, reject) => {
            var tl = anime.timeline({
                easing: 'easeOutExpo'
            });

            tl
            .add({
                targets: '.loadingScreen',
                duration: 200,
                opacity: [0,1]
            })
            .add({
                targets: '.logoLoadingScreen path',
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 2000,
                delay: function(el, i) { return i * 50 },
                direction: 'alternate',
                loop: false
            })
            .add({
                targets: '.logoLoadingScreen path',
                delay: function(el, i) { return i * 50 },
                duration: 1000,
                translateY: [400,0]
            }, '-=2500')
            .add({
                targets: '.logoLoadingScreen',
                fill: ['#000', '#fff'],
                duration: 800
            })
            .add({
                targets: '.logoLoadingScreen',
                opacity: 0,
                duration: 800
            })
            .add({
                targets: '.loadingScreen',
                duration: 1000,
                scaleY: 0,
            })
                
            setTimeout(() => {
                resolve(true)
                document.body.className -= ' preventScroll'
            }
            , 5000)})

        let cookieSetting = await animationDone

        if (cookieSetting && window !== 'undefinded') {
            
            document.cookie="loaded=true"
            document.querySelector('.loadingScreen').style.display = 'none'
            document.querySelector('.logoLoadingScreen').style.display = 'none'
        }
    }


    useEffect(() => {
        
        doLoadingAnimation()
        
    })

  return (
    <div className="loadingScreen h-screen w-screen bg-black flex flex-row justify-center items-center absolute top-0 left-0">
    <div className="loadingScreen h-screen w-screen bg-black flex flex-row justify-center items-center absolute top-0 left-0">
        <Logo className="h-8 lg:h-20 logoLoadingScreen"/>
    </div>
    </div>
  )
}



export default LoadingScreen
