export const data = {
    contactEmail: "hallo@stepone.at",
    adresse: {
        street: 'Finkengasse 25,',
        town: '2514 Traiskirchen'
    },
    socialMedia: {
        facebook: "https://www.facebook.com/stepone.studio",
        instagram: "https://www.instagram.com/stepone.studio",
        behance: "https://www.behance.net/stepone-studio",
        dribbble: "https://www.dribbble.com/stepone"
    }
}