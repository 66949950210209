import React, { useEffect } from 'react';
import {Link} from 'gatsby'
import '../styles/cookieConsent.scss'
import anime from 'animejs/lib/anime.es.js';
import AniLink from "gatsby-plugin-transition-link/AniLink";

import AOS from 'aos';
import 'aos/dist/aos.css';






const CookieConsent = () => {

    function getCookie(cname) {
        if (typeof window !== `undefined`) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
            }
            return "";
        }
      }
    
      function checkCookie() {
        var analytics = getCookie("gatsby-gdpr-google-analytics");
        if (analytics != "") {
            return false
        } else {
            return true;
          }
        }

    useEffect(() => {
        let transformEl = document.getElementsByClassName('tl-wrapper')
        transformEl[0].style.transform = 'none'
        setTimeout(function(){ 
            let cookie = document.getElementById('#cookie-wrapper')
            if (cookie) {
                cookie.style.position = "fixed";
            }
        }, 3000);
    })

    function acceptCookie() {
        let mql = window.matchMedia("(min-width:768px)");

        if (mql.matches) {
            anime({
                targets: '#cookie-wrapper',
                translateY: 400,
                opacity: [1,0],
                easing: 'linear',
                duration: 300
            })
        } else {
            anime({
                targets: '#cookie-wrapper',
                translateY: 400,
                translateX: '-50%',
                opacity: [1,0],
                easing: 'linear',
                duration: 300
            })
        }

        document.cookie = "gatsby-gdpr-google-analytics=true"
        document.cookie = "google-analytics=true"
    }

    function declineCookie() {
        let mql = window.matchMedia("(min-width:768px)");

        if (mql.matches) {
            anime({
                targets: '#cookie-wrapper',
                translateY: 400,
                opacity: [1,0],
                easing: 'linear',
                duration: 300
            })
        } else {
            anime({
                targets: '#cookie-wrapper',
                translateY: 400,
                translateX: '-50%',
                opacity: [1,0],
                easing: 'linear',
                duration: 300
            })
        }

        
        document.cookie = "gatsby-gdpr-google-analytics=false"
        document.cookie = "google-analytics=false"
    }

    return (
        <div>
            {checkCookie() && 
            <div id="cookie-wrapper" className="z-30 fixed bg-white rounded-md h-auto w-11/12 md:w-auto" data-aos="fade-in" data-aos-duration="1000">
            <div className="flex flex-row p-8 md:p-12 justify-between items-center">
                <div id="icon" className="text-4xl md:text-5xl mr-8 md:mr-10">
                🍪
                </div>
                <div className="flex flex-col justify-center">
                    <p>Unsere Website verwendet Cookies um das Nutzererlebnis zu verbessern.</p>
                    <AniLink className="text-soGray underline" to="/datenschutz" paintDrip hex="#000">Mehr Informationen</AniLink>
                    <div className="flex flex-row justify-between">
                        <button onClick={declineCookie} className="mt-2 text-soGray">Bitte nicht</button>
                        <button onClick={acceptCookie} className="mt-2 text-soYellow underline font-medium">Alles klar!</button>
                    </div>
                    
                </div>
            </div>
        </div>
            }
        </div>
    )

};

export default CookieConsent;