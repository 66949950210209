import React, { useEffect } from 'react';
//import { Link } from 'gatsby';
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { data } from '../data/basics'

import '../styles/menu.scss'
import '../styles/animations.scss'

import anime from 'animejs/lib/anime.es.js';
import openMenu from './header'

const mailto = "mailto:" + data.contactEmail

export function menuAnimationIn() {
    //var textWrapper = document.querySelector('.menu-elm');
    //textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span className='letter'>$&</span>");

    anime.timeline({loop: false})
        .add({
            targets: '.menu-elm',
            //translateX: [150,0],
            '-ms-transform': 'translateX(0)',
            '-webkit-transform': 'translateX(0)',
            '-moz-transform': 'translateX(0)',
            '-o-transform': 'translateX(0)',
            'transform': 'translateX(0)',
            opacity: [0,1],
            easing: "easeOutExpo",
            duration: 1400,
            delay: anime.stagger(150)
        }, 100)
        .add({
            targets: ['#social-media', '#email'],
            opacity: [0,1],
            easing: 'easeOutExpo',
            duration: 700
        }, 300);
}

export function menuAnimationOut() {
    anime.timeline({loop: false})
        .add({
            targets: '.menu-elm',
            //translateX: [0,150],
            '-ms-transform': 'translateX(150)',
            '-webkit-transform': 'translateX(150)',
            '-moz-transform': 'translateX(150)',
            '-o-transform': 'translateX(150)',
            opacity: [0,1],
            easing: "easeOutExpo",
            duration: 1400,
            delay: anime.stagger(150)
        })
}

const Menu = () => {

    useEffect(() => {
        var url = location.href.split("/"); //replace string with location.href
        var navLinks = document.getElementsByClassName('menu-elm')
        //naturally you could use something other than the <nav> element
        var i = 0;
        var currentPage = url[url.length - 2];
        for(i; i < navLinks.length; i++){
            var lb = navLinks[i].href.split("/");
            if(lb[lb.length-2] == currentPage) {
                navLinks[i].className += " underline";
            }
        }
    })
    

    return (
        <div id='main-menu' className="p-12 right-0 md:p-12 lg:py-24 lg:px-32 h-screen w-11/12 lg:3/5 xl:w-5/12 z-40 absolute bg-white transition-transform ease-in-out duration-1000 overflow-hidden shadowMenu opacity-0">
            <div id="menu-container" className="flex flex-col justify-between h-full">
                <div id="menu" className="flex flex-col justify-center menu text-5xl lg:text-6xl font-semibold">
                    
                    <AniLink className="menu-elm transition-transform duration-300 ease-out" to="/arbeiten/" paintDrip hex="#000">Arbeiten</AniLink>
                    <AniLink className="menu-elm transition-transform duration-300 ease-out" to="/leistungen/" paintDrip hex="#000">Leistungen</AniLink>
                    <AniLink className="menu-elm transition-transform duration-300 ease-out" to="/studio/" paintDrip hex="#000">Studio</AniLink>
                    <AniLink className="menu-elm transition-transform duration-300 ease-out" to="/kontakt/" paintDrip hex="#000">Kontakt</AniLink>
                </div>
                <div id="social-media" className="flex flex-col text-xl underline">
                    <a className="py-1 hover:text-soYellow ease-in-out duration-300" href={data.socialMedia.facebook}> Facebook </a>
                    <a className="py-1 hover:text-soYellow ease-in-out duration-300" href={data.socialMedia.instagram}> Instagram </a>
                    <a className="py-1 hover:text-soYellow ease-in-out duration-300" href={data.socialMedia.behance}> Behance </a>
                    <a className="py-1 hover:text-soYellow ease-in-out duration-300" href={data.socialMedia.dribbble}> Dribbble </a>
                </div>
                <div id="email" className="text-xl">
                    <a className="underline  hover:text-soYellow ease-in-out duration-300" href={mailto}>{data.contactEmail}</a>
                </div>
            </div>
        </div>
)}

export default Menu;